/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SupportIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12 2.5c5.3 0 9.5 4.3 9.5 9.5s-4.3 9.5-9.5 9.5-9.5-4.2-9.5-9.5S6.7 2.5 12 2.5m0-1.3C6 1.2 1.2 6 1.2 12S6 22.8 12 22.8 22.8 18 22.8 12 18 1.2 12 1.2z" />
		<path d="M11.4 10.4h1.2v6h-1.2zM12 8.9c-.3 0-.6-.3-.6-.6s.3-.6.6-.6.6.3.6.6-.3.6-.6.6z" />
	</svg>
))
SupportIconSvg.displayName = 'SupportIconSvg'

const SupportIcon = forwardRef((props, ref) => <Icon component={SupportIconSvg} ref={ref} {...props} />)
SupportIcon.displayName = 'SupportIcon'

SupportIcon.defaultProps = {
	...Icon.defaultProps,
}
SupportIcon.propTypes = {
	...Icon.propTypes,
}

export default SupportIcon
export { SupportIconSvg }
